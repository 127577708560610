import { fabric } from 'fabric';
import { CurvedLine } from '../../hooks/UseCurvedLine';
import {LINE_TYPES, SHAPE_DEFAULTS, SHAPE_DEFAULTS_AS_SHAPE_PROPS} from '../Constant';
import {
    attachThirdPartyLines, attachThirdPartyShapesToFrames,
    createShapeGroup,
    getCommentColorFromNimaComment,
    getPathStringFromPoints, mapCommentMessage, removeImagesFromList,
} from './Utils';
import OptimizedImage from '../../customClasses/image/OptimizedImage';
import {createObjectToBeEmitted, generateUuidForShape, wait} from '../CommonFunctions';
import { Frame } from '../../hooks/UseFrame';
import generateFrameText from '../frame/GenerateFrameText';
import {SHAPES, THIRD_PARTY_FONTS} from './Constants';
import {Table} from '../table/shapes/Table';
import {generateTableTitle} from '../table/TableEventHandlers';
import {onTableDrawn} from '../table/TableMethods';
import {decodeTextFromBase64, hexToRGBA} from '../CommonUtils';
import {environment} from '../../environment';
import {getNimaUserByGuid, importImages, importMembers} from '../../services/MigrationService';
import renderAllComments from '../comments/RenderAllComments';
import {customToObject} from '../FabricMethods';
import store from '../../redux/Store';
import {NimaThirdPartyImporter} from './NimaThirdPartyImporter';
import {compressData} from '../OptimizationUtils';
import { Socket as SocketType } from 'socket.io-client';


/**
 * Sets necessary properties for the fabric shape that is migrated.
 * @param {fabric.Canvas} canvas Canvas instance.
 * @param {fabric.object} shapeInstance Migrated shape instance.
 * @param {object} data Nima shape's data.
 * @param {MemberListImportResponse} membersFetcher Member's data fetcher.
 * @param {ImportWhiteboardConfig} options Config data.
 */
function setShapeProps(canvas, shapeInstance, data, membersFetcher, options = {}) {
    if (data.locked) {
        shapeInstance.lockMovementX = true;
        shapeInstance.lockMovementY = true;
    }
    if (!shapeInstance.uuid) {
        shapeInstance.uuid = generateUuidForShape(canvas)
    }

    const { wbId } = options;

    if ((!wbId)) {
        console.error('missing whiteboardId')
        return;
    }

    const creatorUser = membersFetcher.getFetchedMember(data.uid);
    const userId = creatorUser?.id ? creatorUser.id : options?.boardOwner.id
    if (!userId) {
        console.error('missing whiteboardId')
        return;
    }

    shapeInstance.originalId = data.id;

    shapeInstance.set({
        whiteBoardId: wbId,
        createdBy: userId,
        modifiedBy: userId,
        isDeleted: false,
        importedFromThirdParty: true,
        hideFromActivityLog: true  // this is for hiding it in logs initially, then we are deleting this prop in activity log tab
    });

    if (shapeInstance.type === 'textbox' || shapeInstance.type === 'path') {
        shapeInstance.shapeType = shapeInstance.type
    }
}

/**
 * Converts nima shapes data to fabric js shapes data.
 * @param {fabric.Canvas} canvas Canvas instance.
 * @param {object[]} shapes Nima board's shape list.
 * @param {object[]} uploadedImages Uploaded image list for setting imageData of the images.
 * @param {MemberListImportResponse} membersFetcher Member's data fetcher.
 * @param {ImportWhiteboardConfig} options Config data.
 * @returns {Promise<fabric.Object[]>} List of converted shapes.
 */
async function convertNimaShapes(canvas, shapes, uploadedImages, membersFetcher, options = {}) {
    const createdShapes = []

    for (const data of shapes) {
        try {
            switch (data.cmd) {
                case SHAPES.RECTANGLE: {
                    const rect = new fabric.Rect({
                        width: data.w,
                        height: data.h,
                        left: data.x,
                        top: data.y,
                        fill: data.fill ? hexToRGBA(data.fill) : SHAPE_DEFAULTS.FILL,
                        stroke: data.stroke ? hexToRGBA(data.stroke) : SHAPE_DEFAULTS.STROKE,
                        strokeWidth: SHAPE_DEFAULTS.STROKE_WIDTH,
                    });
                    const rectGroup = createShapeGroup(rect, data)
                    setShapeProps(canvas, rectGroup, data, membersFetcher, options)

                    createdShapes.push(rectGroup);
                    break;
                }
                case SHAPES.CIRCLE: {
                    const ellipse = new fabric.Ellipse({
                        left: data.x - data.r,
                        top: data.y - data.r,
                        rx: data.r,
                        ry: data.r,
                        fill: data.fill ? hexToRGBA(data.fill) : SHAPE_DEFAULTS.FILL,
                        stroke: data.stroke ? hexToRGBA(data.stroke) : SHAPE_DEFAULTS.STROKE,
                        strokeWidth: data.lineWidth,
                    })
                    const ellipseGroup = createShapeGroup(ellipse, data)
                    setShapeProps(canvas, ellipseGroup, data, membersFetcher, options)
                    createdShapes.push(ellipseGroup)
                    break;
                }
                case SHAPES.STICKY_NOTE: {
                    const stickyNote = new fabric.Rect({
                        width: data.w,
                        height: data.h,
                        left: data.x,
                        top: data.y,
                        rx: 10,
                        ry: 10,
                        fill: data.color ? hexToRGBA(data.color) : SHAPE_DEFAULTS.FILL,
                        shadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                    });
                    const stickyNoteGroup = createShapeGroup(stickyNote, data)
                    setShapeProps(canvas, stickyNoteGroup, data, membersFetcher, options)
                    createdShapes.push(stickyNoteGroup)
                    break;
                }
                case SHAPES.TEXT: {
                    const text = new fabric.Text(data.value, {
                        fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
                        fontSize: data.size * 1.333,
                        left: data.x,
                        top: data.y,
                        objectCaching: false,
                        textAlign: data.align || 'left'
                    });
                    const actualFontSize = data.size * 1.333;

                    const textBox = new fabric.Textbox(data.value, {
                        fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
                        fontSize: actualFontSize,
                        left: data.x,
                        top: data.y,
                        objectCaching: false,
                        width: text.width + 10,
                        fill: data?.color ? hexToRGBA(data.color) : SHAPE_DEFAULTS.TEXT_COLOR,
                        textAlign: data.align || 'left'
                    });
                    setShapeProps(canvas, textBox, data, membersFetcher, options)
                    createdShapes.push(textBox);
                    break;
                }
                case SHAPES.LINE: {
                    const points = [
                        new fabric.Point(data.points[0].x, data.points[0].y),
                        new fabric.Point(data.points[1].x, data.points[1].y)
                    ]
                    const line = new CurvedLine(points, {
                        stroke: data.color,
                        strokeWidth: data.lineWidth,
                        originY: 'center',
                        originX: 'center',
                        arrowEnabled: true,
                        arrowLeft: data?.type === 'barrow' || data.type === 'rarrow',
                        arrowRight: data?.type === 'arrow' || data?.type === 'barrow',
                        lineType: LINE_TYPES.STRAIGHT,
                        isInitiallyConnector: data?.type === 'arrow'
                    });
                    if (data.type === 'dashed' || data.type === 'dotted') {
                        line.set('strokeDashArray', [5, 5])
                    }
                    setShapeProps(canvas, line, data, membersFetcher, options)
                    createdShapes.push(line)
                    break;
                }
                case SHAPES.IMAGE:
                    try {
                        if (!data.resource) {
                            console.error('image has no resource', data)
                            continue
                        }
                        const uploadedImg = uploadedImages?.find(img => img?.resourceId === data.resource)
                        if (!uploadedImg) {
                            console.error('could not find the uploaded image', data.resource, uploadedImages)
                            continue;
                        }
                        let resource = data.resource;
                        const image = new OptimizedImage(null, {
                            width: data.w,
                            height: data.h,
                            left: data.x,
                            top: data.y,
                            imageData: uploadedImg.imageData,
                            resource: resource,
                            addWithoutImgEl: true
                        });
                        setShapeProps(canvas, image, data, membersFetcher, options)
                        createdShapes.push(image)
                    } catch (err) {
                        console.error('error while importing image', err)
                    }
                    break;
                case SHAPES.PEN: {
                    const path = getPathStringFromPoints(data.points)
                    const object = new fabric.Path(path, {
                        stroke: data.color,
                        fill: 'transparent',
                        strokeWidth: SHAPE_DEFAULTS.STROKE_WIDTH,
                    })
                    setShapeProps(canvas, object, data, membersFetcher, options)
                    createdShapes.push(object);
                    break;
                }
                case SHAPES.FRAME: {
                    const thickness = 4 / 2;  // 4 is the stroke width of the frame
                    const shadow = new fabric.Shadow({
                        color: '#b388ff70',
                        blur: 3,
                    });

                    const frame = new Frame({
                        left: data.x - thickness,
                        top: data.y - thickness,
                        width: data.w,
                        height: data.h,
                        stroke: SHAPE_DEFAULTS_AS_SHAPE_PROPS.stroke,
                        strokeWidth: 1,
                        fill: !data?.color ? 'rgba(255, 255, 255, 0.5)' : hexToRGBA(data.color),
                        objectCaching: false,
                        shadow,
                        text: data.title || generateFrameText(canvas)
                    });

                    setShapeProps(canvas, frame, data, membersFetcher, options)
                    createdShapes.push(frame);
                    break;
                }
                case SHAPES.COMPOSITE: {
                    const metadata = data?.metadata?.['wt-df'];
                    if (metadata?.type !== 'tabular') {
                        console.log('skipping composite since its not a table', data)
                        continue
                    }

                    const tableInfoStr = metadata?.data?.table;
                    const rows = tableInfoStr.split('\n');
                    const tableInfo = rows.map(row => row.split(','));

                    const rowCount = tableInfo.length;
                    const columnCount = tableInfo[0].length;
                    const { x, y } = data.shapes[0];
                    const hasBorder = data?.shapes?.some(shape => shape.cmd === 'rectangle');

                    // since nima cuts empty textbox in shapes list, create new array to include empty texts
                    const textboxShapes = []
                    if (!hasBorder) {
                        let realIdx = 0;
                        for (const row of tableInfo) {
                            for (const col of row) {
                                if (col) {
                                    textboxShapes.push(data.shapes[realIdx])
                                    realIdx++
                                } else {
                                    textboxShapes.push({ notExist: true, cmd: 'emptyText' })
                                }
                            }
                        }
                    }

                    const tableCols = Array.from({length: columnCount}, () => ({ width: 0 }) )

                    try {
                        // check if the table has border

                        // get the table shapes.
                        // if the table has border, shapes will include rectangle shape. In that case we only need rectangles
                        const tableShapes = hasBorder ? data.shapes.slice(0, data.shapes.length / 2) : textboxShapes;

                        let columnIdx = -1;
                        for (let shapeIdx = 0; shapeIdx < tableShapes?.length; shapeIdx++) {
                            // get the column id for this shape
                            columnIdx++;
                            if (columnIdx > tableCols.length - 1) {
                                columnIdx = 0;
                            }

                            const shape = tableShapes[shapeIdx];
                            let widthOfShape = 0;
                            if (shape.cmd === 'rectangle') {
                                widthOfShape = shape.w
                            } else if (shape.cmd === 'text') {
                                widthOfShape = shape.getBoundingRect().w;
                            }

                            // find maximum width for this column
                            if (tableCols[columnIdx].width < widthOfShape) {
                                tableCols[columnIdx].width = widthOfShape;
                            }
                        }
                    } catch (err) {
                        tableCols.length = 0;  // in case of any error, use default table width
                    }

                    const table = new Table({
                        left: x,
                        top: y,
                        lockScalingFlip: true,
                        totalRows: rowCount,
                        totalCols: columnCount,
                        cellTexts: tableInfo,  // initialize the table with the texts
                        cellTextPadding: 1,
                        defaultCellHeight: 20,
                        cols: tableCols
                    })
                    setShapeProps(canvas, table, data, membersFetcher, options)
                    createdShapes.push(table);
                    break;
                }
                default:
                    console.log('unknown shape', data);
                    break;
            } 
        } catch (err) {
            console.error('error on creating shapes: ', err)
        }
    }


    // add the shapes
    for (const shape of createdShapes) {
        // generate the title
        if (shape.type === 'table') {
            shape.title = generateTableTitle(canvas);
        }
        canvas.add(shape)
        if (shape.type === 'table') {
            onTableDrawn(shape);
        }
        if (shape.type === 'group' && shape.shapeType === 'sticky') {
            const textboxObj = shape?.getObjects()?.find((obj) => obj.type === 'textbox');
            if (textboxObj) {
                textboxObj.initDimensions();
            }
        }
    }
    return createdShapes;
}

/**
 * Get the member list of the board.
 * @param {ImportWhiteboardConfig} config Config data.
 * @returns {Promise<{ memberList: object[], boardId: string }>} Members and boardId of the 3p board.
 */
async function getMemberList(config) {
    console.log('getting member list')
    const { nimaWbId, guid } = config;
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const clientId = environment.NIMA_BOARD_CLIENT_ID;
    const negotiationUrl = `https://www.whiteboard.team/board-hub/negotiate?uid=${guid}&negotiateVersion=1`
    const negotiationData = await fetch(negotiationUrl, fetchOptions).then(res => {
        return res.json()
    }).catch(err => {
        console.error('an error has occurred while getting whiteboard team data', err)
    })

    if (!negotiationData) {
        return Promise.reject('could not establish the ws connection')
    }

    let timeoutId;

    const onMeJoinedData = await new Promise((resolve, reject) => {
        const url = `wss://www.whiteboard.team/board-hub?uid=${guid}&id=${negotiationData.connectionToken}`
        const ws = new WebSocket(url)
        ws.onopen = async () => {
            console.log('socket connection (3-party whiteboard) is established')
            ws.send('{"protocol":"json","version":1}')
            ws.send(`{"arguments":[null,"${nimaWbId}","${clientId}",{"board":{},"participant":{"id":"${guid}"},"width":732,"height":751}],"invocationId":"0","target":"joinBoard","type":1}`)

            timeoutId = setTimeout(() => {
                reject('timeout')
            }, 15000)
        }

        const handleMessage = (msg) => {
            // eslint-disable-next-line no-control-regex
            const safeMessage = msg?.replaceAll(/\u001E/g, '')  // they are adding record separator (RS) character at the end of the messages...
            const messageData = JSON.parse(safeMessage);
            if (messageData?.type === 1 && messageData?.target === 'onMeJoined') {

                const boardId = messageData?.arguments[0]?.boardId;
                const memberList = messageData?.arguments[0]?.members;
                if (!Array.isArray(memberList)) {
                    reject('member list is not an array')
                } else {
                    resolve({ memberList, boardId })
                }
            } else if (messageData?.target === 'onClientError') {
                reject('client error')
            } else if (messageData?.type === 3 && messageData.error) {
                reject('board data is wrong. could not proceed')
            } else if (messageData?.target === 'onAccessDenied') {
                reject('access denied');
            } else if (messageData?.target === 'onBoardNotFound') {
                reject('board not found');
            }
        }

        ws.onerror = (err) => {
            console.error('ws error', err)
            reject('could not establish socket connection');
        }
        ws.onmessage = (msg) => {
            clearTimeout(timeoutId)
            try {
                // eslint-disable-next-line no-control-regex
                const messages = msg?.data?.split(/\u001E/g)
                messages.filter(msg => msg !== '').forEach(msg => handleMessage(msg))
            } catch (err) {
                console.error('error while parsing message', err)
            }
        }
        ws.onclose = () => {
            console.log('socket connection (3-party whiteboard) is closed')
            reject('socket connection is closed')
        }
    })

    if (!onMeJoinedData || !onMeJoinedData.memberList || !onMeJoinedData.memberList?.length || !onMeJoinedData.boardId) {
        return Promise.reject('necessary data for fetching users could not found')
    }
    
    const memberListInNimaRes = await fetch(`https://www.whiteboard.team/api/boards/${onMeJoinedData.boardId}/sharing?Uid=${guid}`)
    const memberListInNima = await memberListInNimaRes.json()
    
    const mappedMembers = onMeJoinedData.memberList.map((member, index) => {
        const memberData = {
            uid: member.uid,
            name: member.name,
            nimaFound: true,
            email: null
        }
        
        const memberDataInSharingList = memberListInNima?.members[index]
        if (memberDataInSharingList && memberDataInSharingList?.name === member.name) {
            memberData.nimaId = memberDataInSharingList.id;
        } else {
            memberData.nimaFound = false;
        }
        
        return memberData
    })
    

    const { boardId= '' } = onMeJoinedData;
    for (const nimaMember of mappedMembers) {
        try {
            const fetchedMemberData = await getNimaUserByGuid(nimaMember.uid)
            if (!fetchedMemberData) {
                nimaMember.isFetchedData = false;
                continue
            }
            nimaMember.isFetchedData = true;
            nimaMember.email = fetchedMemberData.email
            if (nimaMember.name !== fetchedMemberData.name) {
                if (nimaMember.name === 'null' || !nimaMember.name) {
                    nimaMember.name = fetchedMemberData.name
                } else if (fetchedMemberData.name !== 'null' && fetchedMemberData.name.length > nimaMember.name.length) {
                    nimaMember.name = fetchedMemberData.name
                }
            }
        } catch (err) {
            console.error(err)
        }
    }
    
    return {memberList: mappedMembers, boardId};
}

/**
 * Map comments with the imported data.
 * If the comment creator could not be imported, we map it with the owner (set userId of the same as with the owner's userId) 
 * and using nimaName to point out the user that we couldn't import.
 * @param {fabric.Canvas} canvas Canvas instance.
 * @param {object[]} commentShapes Nima board's comment shapes.
 * @param {object} userMaps Map for users.
 * @param {Map<string, object>} userMaps.usersGuidMap Users map with guids.
 * @param {Map<string, object>} userMaps.usersUidMap Users map with ids.
 * @param {ImportWhiteboardConfig} config Config data.
 * @returns {Promise<object[]>} Mapped comments data. This data can be used to import comments directly.
 */
export async function mapComments(canvas, commentShapes, { usersGuidMap, usersUidMap }, config = {}) {
    const { nimaWbId, guid } = config;

    const commentData = await fetch(`https://www.whiteboard.team/api/boards/${nimaWbId}/comments/?Uid=${guid}`, {
        'headers': {
            'accept': 'application/json, text/plain, */*',
        },
    }).then(res => {
        return res.json()
    }).catch(err => {
        console.error('an error has occurred while getting whiteboard team data', err)
    })

    if (!commentData) {
        return;
    }

    return commentShapes.map(comment => {
        const commentThread = commentData.find(data => data.threadId === comment.threadId)
        if (!commentThread) {
            return null;
        }

        return {
            position: {
                x: comment.x,
                y: comment.y
            },
            colorCode: getCommentColorFromNimaComment(comment.color),
            pageId: config?.activePageId,
            resolved: comment.resolved,
            threads: commentThread.comments.map(thread => {
                const createdByUser = usersGuidMap.has(thread.createdBy) ? usersGuidMap.get(thread.createdBy) : usersUidMap.get(thread.createdBy)
                const mappedMessage = mapCommentMessage(thread.message, usersGuidMap, usersUidMap);

                const mappedThread = {
                    content: mappedMessage.oldTypeMessage,
                    comment_content: mappedMessage.newTypeMessage,
                    createdDate: thread.createdDate,
                }
                
                if (createdByUser) {
                    // if comment creator exist, assign comment to them 
                    if (createdByUser?.boardMember?.id) {
                        mappedThread.userId = createdByUser.boardMember.id;
                    } else {
                        // else, we need to assign it to the board owner
                        mappedThread.userId = config?.boardOwner?.id;
                        mappedThread.nimaName = createdByUser.name;
                    }
                } else {
                    mappedThread.userId = config?.boardOwner?.id;
                }

                if (mappedMessage?.taggedUsers?.length) {
                    mappedThread.tagged_user_ids = mappedMessage.taggedUsers
                    mappedThread.taggedUserIds = mappedMessage.taggedUsers
                }
                return mappedThread;
            })
        }
    })
}


/**
 * Fetches the board data (commands) and processes it.
 * @param {fabric.Canvas} canvas Canvas instance. 
 * @param {ImportWhiteboardConfig} options Config data.
 * @returns {Promise<Awaited<{ shapes: object[], comments: object[], imageResources: Set, imageCount: number }>>} Processed 3p board data.
 */
export async function fetchAndProcessShapeData(canvas, options = {}) {
    const { nimaWbId, guid } = options

    const dataUrl = `https://www.whiteboard.team/api/boards/${nimaWbId}/commands?Uid=${guid}`

    const data = await fetch(dataUrl).then(res => {
        return res.json()
    }).catch(err => {
        console.error('an error has occurred while getting third-party whiteboard data', err)
        return null;
    })

    if (!data) {
        return Promise.reject('could not fetch the shapes data')
    }
    const importer = new NimaThirdPartyImporter();
    const isInvoked = await importer.invoke(data)
    if (!isInvoked) {
        return Promise.reject('could not handle the commands')
    }
    
    const shapeList = []
    const comments = [];
    let imageCount = 0;
    const imageResources = new Set();
    for (const shape of importer.drawingQueue.shapes) {
        if (shape.cmd === SHAPES.COMMENT) {
            comments.push(shape)
            continue
        }
        shapeList.push(shape)
        if (shape?.cmd === SHAPES.IMAGE && shape.resource) {
            imageCount++;
            imageResources.add(shape.resource)
        }
    }
    return Promise.resolve({shapes: shapeList, comments, imageResources, imageCount})
}

/**
 * Adds given shapes to canvas.
 * @param {fabric.Canvas} canvas Canvas instance.
 * @param {fabric.Object[]} shapes Shapes to add current canvas instance.
 * @param {object} uploadedImages Uploaded image list that is returned in migrating images API.
 * @param {MemberListImportResponse} membersFetcher Member's data fetcher.
 * @param {ImportWhiteboardConfig} options Config data.
 * @returns {Promise<Awaited<*[]>>} Returns created shape instances.
 */
async function addShapesToCanvas(canvas, shapes, uploadedImages, membersFetcher, options = {}) {
    try {
        console.log('adding shapes to canvas')
        const createdShapes = await convertNimaShapes(canvas, shapes, uploadedImages, membersFetcher, options)

        // handle attaching objects to frames
        const frames = shapes.filter((obj) => obj.cmd === SHAPES.FRAME && obj?.children?.length)
        const canvasObjects = canvas.getObjects()
        if (frames.length) attachThirdPartyShapesToFrames(canvasObjects, frames)

        // handle attaching lines
        const lines = shapes.filter((obj) => obj.cmd === SHAPES.LINE)
        if (lines.length) attachThirdPartyLines(canvasObjects, lines)

        return Promise.resolve(createdShapes)
    } catch (err) {
        return Promise.reject(err)
    }
}


/**
 * Loads the fonts that might be used in third party board. This is crucial for calculating bounding box of the text boxes.
 * @returns {Promise<void>}
 */
async function loadFonts() {
    try {
        const fontPromises = THIRD_PARTY_FONTS.filter(font => font.url).map(font => {
            return new FontFace(font.name, `url(${font.url})`).load()
        })

        const fontLoadStats = await Promise.allSettled(fontPromises)
        if (fontLoadStats.some(stat => stat.status === 'rejected')) {
            console.error('error while loading some fonts', fontLoadStats.filter(stat => stat.status === 'rejected'))
        } else {
            console.log('all third party fonts are loaded')
        }
    } catch (err) {
        console.warn("couldn't load the fonts", err)
    }
    return Promise.resolve()
}

/**
 * Wait for the images to be uploaded.
 * Listens to the imagesUploaded event.
 * @param socket
 * @param {Set} images images to be waited
 * @param canvas canvas instance
 * @param {string} wbSlugId
 * @param {string} boardId nima board's id
 * @param {object} returnData It will be passed to the recursive functions, after all the attempts are done, this object will be returned
 * @param {number} attempt in which attempt we are -- starts from 0
 * @returns {Promise<unknown>}
 */
async function waitImagesToBeUploaded(socket, images, canvas, wbSlugId, firstImageWaitListener, boardId, returnData = {}, attempt = 0) {
    if (!images?.size) {
        return Promise.reject('no images to be uploaded')
    }

    let importImagesResponse;
    // if this function called in this function, that means we need to try to upload failed images
    if (attempt > 0) {
        try {
            await wait(3000)
            importImagesResponse = await importImages(Array.from(images), wbSlugId, boardId)
            const canvasObjects = canvas.getObjects()
            for (const image of importImagesResponse.images) {
                const imageInstances = canvasObjects.filter(obj => obj.resource === image.resourceId)
                for (const imageInstance of imageInstances) {
                    imageInstance.imageData = image.imageData
                }
            }
        } catch (err) {
            console.error(`error while trying to import again (atttempt: ${attempt}: ${err}`)
        }

    }

    const uploadListenerResponse = await new Promise((resolve) => {
        if (attempt === 0 && firstImageWaitListener && firstImageWaitListener.isArrived) {
            firstImageWaitListener.dispose()
            resolve(firstImageWaitListener.data)
        }
        const imagesUploadedListener = (data) => {
            resolve(data)
        }
        socket.on('imagesUploaded_migration', imagesUploadedListener)
    });
    if (attempt === 0) {
        returnData = uploadListenerResponse
    } else if (uploadListenerResponse?.imagesUploaded) {  // if this retry for uploading images, and some of the images are uploaded
        // handle the arrays of the status of the images
        returnData.filesUploaded.push(...uploadListenerResponse.filesUploaded)
        returnData.filesNotUploaded.push(...uploadListenerResponse.filesNotUploaded)
        returnData.filesNotDownloaded.push(...uploadListenerResponse.filesNotDownloaded)

        // then search the successfully uploaded file in not uploaded arrays and delete them since we moved them to the files uploaded array
        for (const uploadedFile of uploadListenerResponse.filesUploaded) {
            const foundIndexInNotUploadedArray = returnData?.filesNotUploaded.indexOf(uploadedFile)
            const foundIndexInNotDownloadedArray = returnData?.filesNotDownloaded.indexOf(uploadedFile)
            if (foundIndexInNotUploadedArray !== -1) {
                returnData.filesNotUploaded.splice(foundIndexInNotUploadedArray, 1)
            }
            if (foundIndexInNotDownloadedArray !== -1) {
                returnData.filesNotDownloaded.splice(foundIndexInNotUploadedArray, 1)
            }
        }
    }


    // if some of the images are not uploaded, and we can still attempt to retry, then retry to upload failed images
    if ((returnData?.filesNotUploaded?.length || returnData?.filesNotDownloaded?.length) && attempt < 2) {
        return await waitImagesToBeUploaded(
            socket,
            new Set([...returnData.filesNotUploaded, ...returnData.filesNotDownloaded]), // images that we will try to upload again
            canvas,
            wbSlugId,
            null,
            boardId,
            returnData,
            attempt + 1  // here increase the attempt
        )
    }
    // if we don't have any not uploaded images or we exceed the maximum number of attempts
    return returnData
}

/**
 * Type for board members.
 * @typedef {object} ImportWhiteboardUsersList
 * @property {string} email User email data.
 * @property {number} id User's id.
 * @property {string} name User's name.
 * @property {string} commentUserName Comment username data for mapping comments.
 * @property {boolean} isOwner If true, the user is the owner of the current board.
 */

/**
 * Type for config data.
 * @typedef {object} ImportWhiteboardConfig
 * @property {string} nimaWbId Third party board id.
 * @property {string} guid Uuid of the user that has access to 3p board.
 * @property {number} activePageId Id of the page that the data will be imported in.
 * @property {string} wbSlugId Slug id of the `current` board.
 * @property {number} wbId `current` board id.
 * @property {number} userId Active user's id.
 * @property {SocketType} socket Socket instance.
 * @property {boolean} shouldHideComments Necessary for importing comments. If true, comments will be hidden.
 * @property {boolean} shouldHideResolvedComments Necessary for importing comments. If true, resolved comments will be hidden.
 * @property {Function} setSelectedCommentIcon For making comments selectable.
 * @property {ImportWhiteboardUsersList[]} users User list of the board.
 * @property {ImportWhiteboardUsersList} boardOwner Board owner's data.
 * @property {NimaThirdPartyImporter} analyzer Analyzer class for analyzing 3p migration.
 * @property {(userList: object[]) => void} addUsersToStore Method for adding newly created users to the store.
 */


/**
 * Imports third party board (nima board).
 * @param {fabric.Canvas} canvas Canvas instance of the active page.
 * @param {ImportWhiteboardConfig} config Config to help importing data.
 * @returns {Promise<boolean>} True if the process is finished successfully.
 */
export async function importWhiteboardTeamData(canvas, config = {}) {
    const {
        wbSlugId,
        nimaWbId,
        guid,
        boardOwner,
        analyzer,
        addUsersToStore
    } = config;

    if (!nimaWbId || !guid) {
        return Promise.reject('nima board ID and User id are required')
    }
    
    if (!boardOwner?.email) {
        return Promise.reject('could not find the board owner')
    }

    await loadFonts()

    const [shapePromise, memberListPromise] = await Promise.allSettled([
        fetchAndProcessShapeData(canvas, config),
        getMemberList(config)
    ])

    if (memberListPromise.status === 'rejected') {
        return Promise.reject(memberListPromise.reason)
    }
    if (shapePromise.status !== 'fulfilled') {
        console.error('could not fetch the shapes', shapePromise.reason)
        return Promise.reject("couldn't fetch the shapes", shapePromise.reason)
    }

    const { shapes: nimaShapes, imageResources, comments, imageCount  } = shapePromise.value;
    const { memberList, boardId } = memberListPromise.value;

    analyzer.totalShapeCount = nimaShapes.length - imageCount;  // images will be analyzed differently
    analyzer.totalCommentCount = comments.length;
    analyzer.totalImageCount = imageCount;
    analyzer.totalMemberCount = memberList.length;
    
    try {
        const membersFetcher = await _handleImportingMembersAndComments(canvas, memberList, comments, config);
        addUsersToStore(membersFetcher.newlyAddedUsers)
        const firstImageWaitListener = new FirstImageWaitListener(config.socket, imageResources?.size);
        const importImagesResponse =
            imageResources?.size ? await importImages(Array.from(imageResources), wbSlugId, boardId) : { images: [] }
        const addedShapes = await addShapesToCanvas(canvas, nimaShapes, importImagesResponse?.images, membersFetcher, config).then((shapes) => {
            console.log(`${shapes?.length} shapes are added to the canvas`)
            return shapes
        }).catch(err => {
            console.error('error while adding shapes to canvas', err)
            return null;
        })

        const shapePromise = await _handleEmittingShapes(canvas, addedShapes, imageResources, config, boardId, firstImageWaitListener);
        
        // clear users map
        membersFetcher?.disposeUsersMap();
        return shapePromise
    } catch (err) {
        console.error('error on 3p migration process', err)
        return false;
    }
}

class FirstImageWaitListener {
    constructor(socket, size) {
        this.isArrived = false;
        this.socket = socket;
        this.listener = this.listener.bind(this)
        if (size) {
            this.socket.on('imagesUploaded_migration', this.listener)
        }
    }
    listener(data) {
        console.log('yea')
        this.data = data
        this.isArrived = true
    }
    dispose() {
        this.isArrived = false;
        this.socket.off('imagesUploaded_migration', this.listener)
    }
}

/**
 * Emits migrated shapes.
 * @param {fabric.Canvas} canvas Canvas instance.
 * @param {fabric.Object[]} shapes Shapes to emit shapeCreated event.
 * @param {Set} imageResources Array of image resource ids.
 * @param {ImportWhiteboardConfig} config Config for importing members and comments. It involves board, user and socket data.
 * @param {string} boardId Nima board's board id.
 * @returns {Promise<Awaited<boolean>>} True if the shapes are emitted.
 * @private
 */
async function _handleEmittingShapes(canvas, shapes, imageResources, config, boardId, firstImageWaitListener) {
    if (!shapes?.length) {
        return Promise.reject('shapes are missing')
    }

    const {
        wbSlugId,
        wbId,
        userId,
        activePageId,
        socket,
        analyzer
    } = config;
    
    analyzer.addImportedShapes(shapes.filter(shape => shape.type !== 'optimizedImage')?.length)

    const emitShapes = (addToHistory = true) => {
        canvas.fire('history-emit-data', {
            objects: shapes,
            action: 'migrationCreated',
            createObjectOptions: {
                hideLog: true
            }
        })

        // add shapes to the history for supporting undo-redo
        if (addToHistory) {
            const objectJSONForHistory = []
            const canvasObjects = canvas.getObjects()

            for (const shape of shapes) {
                try {
                    const shapeInCanvas = canvasObjects.find(o => o.uuid === shape.uuid)
                    if (!shapeInCanvas) {
                        console.log('skipping for history', shape.uuid)
                        continue
                    }
                    objectJSONForHistory.push(
                        createObjectToBeEmitted(
                            wbId,
                            userId,
                            customToObject(shapeInCanvas, { shouldCopyDeeply: true }),
                            false,
                            shape.shapeType
                        )
                    )

                } catch (err) {
                    console.error('error while adding shape to the history', err, shape)
                }
            }

            try {
                store.dispatch({
                    type: 'history/addShapesToHistory',
                    payload: {
                        shapes: objectJSONForHistory,
                        pageId: activePageId
                    }
                })
            } catch (err) {
                console.error('error while adding shapes to the history', err)
            }
        }
    }
    
    if (!imageResources.size) {
        emitShapes()
        return Promise.resolve(true)
    }

    try {
        const data = await waitImagesToBeUploaded(socket, imageResources, canvas, wbSlugId, firstImageWaitListener, boardId)
        // if some images are not uploaded, remove them from the shapes list to make sure we are not sending them to the backend
        if (data?.filesNotUploaded?.length || data?.filesNotDownloaded?.length) {
            const failedToUploadImages = []
            try {
                failedToUploadImages.push(...data.filesNotUploaded);
                failedToUploadImages.push(...data.filesNotDownloaded);
            } catch (err) {
                console.error(err)
            }
            console.error('some images are not uploaded', failedToUploadImages)
            const notUploadedImageInstances = shapes.filter(obj => obj.type === 'optimizedImage' && failedToUploadImages.includes(obj.resource))
            removeImagesFromList(canvas, shapes, notUploadedImageInstances)
        }
        const allImportedImages = shapes.filter(o => o.type === 'optimizedImage')
        analyzer.addImportedImages(allImportedImages.length)
        for (const img of allImportedImages) {
            img.loader = true; // images will be loaded automatically with this flag
        }

        canvas.requestRenderAll()
    } catch (err) {
        console.error('error while handling emitting shapes', err)
        return Promise.reject(err)
    }

    emitShapes()
    return Promise.resolve(true)
}

/**
 * @typedef {object} MemberListImportResponse
 * @property {object[]} newlyAddedUsers List of new added users.
 * @property {(memberKey: string) => {id: number, name: string, email: string}|null } getFetchedMember Returns fetched user.
 * @property {() => void} disposeUsersMap Clears all the users map.
 */

/**
 * Imports members and comments.
 * @param {fabric.Canvas} canvas Canvas instance.
 * @param {object[]} members Member list to import.
 * @param {{x: number, y: number, threadId: string, createdBy: string}[]} comments Nima board comments data. 
 * @param {ImportWhiteboardConfig} config Config for importing members and comments. It involves board, user and socket data.
 * @returns {MemberListImportResponse} Returns the users mapped with guid and uid.
 * @private
 */
async function _handleImportingMembersAndComments(canvas, members, comments, config) {
    const {
        wbSlugId,
        userId,
        shouldHideComments,
        shouldHideResolvedComments,
        users: boardUsers,
        socket,
        analyzer
    } = config;
    const usersEmailMap = new Map();
    boardUsers.forEach(user => usersEmailMap.set(user.email, user))

    const addingMembersPayload = members.filter(member => member?.isFetchedData && member?.email && !usersEmailMap.has(member.email))?.map(member => ({
        name: member?.name,
        email: member?.email,
        guid: member?.nimaId
    }))
    
    analyzer.addImportedMembers(members.filter(member => member?.isFetchedData && member?.email)?.length)

    const newlyAddedUsers = [];
    if (addingMembersPayload.length) {
        const { users: importedMembers } = await importMembers(addingMembersPayload, wbSlugId)
        importedMembers
            .map(member => ({
                ...member,
                email: decodeTextFromBase64(member.encodedEmail) // get decoded email
            }))
            .filter(member => !usersEmailMap.has(member.email)) // get only new members
            .forEach(addedMember => {
                newlyAddedUsers.push(addedMember)
                usersEmailMap.set(addedMember.email, addedMember)
            })
    }

    // create two maps. one for guid and one for uid
    // both of the maps will point out the user
    const usersGuidMap = new Map();
    const usersUidMap = new Map();
    for (const member of members) {
        const mapData = {
            ...member
        }
        if (member.email) {
            const memberInBoardUsers = usersEmailMap.get(member.email)
            if (memberInBoardUsers.id) {
                mapData.boardMember = memberInBoardUsers
            }
        }
        usersGuidMap.set(member.nimaId, mapData)
        usersUidMap.set(member.uid, mapData)
    }
    
    if (comments?.length) {
        const mappedComments = await mapComments(canvas, comments, {members, usersGuidMap, usersUidMap}, config)
        if (!mappedComments?.length) {
            return false;
        }
        const addedCommentResponse = await _importComments(mappedComments, socket)
        if (addedCommentResponse?.commentInitData && addedCommentResponse.commentInitData.length) {
            analyzer.addImportedComments(addedCommentResponse.commentInitData.length)
            renderAllComments({
                canvas,
                comments: addedCommentResponse.commentInitData,
                setSelectedCommentIcon: config.setSelectedCommentIcon,
                userId,
                shouldHideComments,
                shouldHideResolvedComments
            }) 
        }
    }
    
    return {
        newlyAddedUsers,
        getFetchedMember: (memberKey) => {
            // search in guid list
            if (usersGuidMap.has(memberKey)) {
                const user = usersGuidMap.get(memberKey)
                if (user?.boardMember?.id) {
                    return user.boardMember;
                }
            }

            // search in uid map
            if (usersUidMap.has(memberKey)) {
                const user = usersUidMap.get(memberKey)
                if (user?.boardMember?.id) {
                    return user.boardMember;
                }
            }
            
            return null
        },
        disposeUsersMap: () => {
            usersGuidMap.clear()
            usersUidMap.clear()
        }
    }
}

/**
 * Sends an import comment request with a compressed body with socket.
 * @param {object[]} comments Comments data to import.
 * @param {SocketType} socket Socket.io instance.
 * @returns {Promise<{ message: string, commentInitData: object[] }>} Response for sending data.
 * @private
 */
async function _importComments(comments, socket) {
    return new Promise((resolve, reject) => {
        const socketPayload = compressData({ commentThreads: comments })
        socket.emit('addCommentThreadNima', socketPayload, (res) => {
            try {
                const data = JSON.parse(res?.emitData)
                resolve(data)
            } catch (err) {
                reject(err)
            }
        })
    })
}